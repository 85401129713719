let sampleMenu = {
  menus: [
    {
      menuTitle: "Lunch",
      hours: "11:30AM - 2:30PM",
      sections: [
        {
          sectionTitle: "Appetizers",
          items: [
            {
              name: "Peperonata",
              price: "6.95",
            },
            {
              name: "Eggplant",
              price: "6.95",
            },
            {
              name: "Garlic Bread",
              price: "4.95",
            },
            {
              name: "Pizza Bread",
              price: "5.95",
            },
          ],
        },
        {
          sectionTitle: "Soups",
          sectionSubtitle: "(Changed daily)",
          groupPrice: [
            {
              name: "Cup",
              price: "4.95",
            },
            {
              name: "Bowl",
              price: "6.95",
            },
          ],
          items: [
            {
              name: "Pasta e fagioli",
            },
            {
              name: "Lentil",
            },
            {
              name: "Vegetable",
            },
            {
              name: "Minestrone",
            },
            {
              name: "Split Pea",
            },
            {
              name: "Stracciatella",
            },
            {
              name: "Cabbage Potato",
            },
          ],
        },
        {
          sectionTitle: "Salads",
          items: [
            {
              name: "Dinner Salad",
              price: "3.95",
            },
            {
              name: "Giuseppe's Salad",
              price: "10.95",
            },
            {
              name: "Provolone",
              price: "10.95",
            },
            {
              name: "Antipasto",
              price: "10.95",
            },
            {
              name: "Tomato Salad",
              price: "10.95",
            },
            {
              name: "Mozzarella Caprese",
              price: "10.95",
            },
          ],
        },
        {
          sectionTitle: "House Specialties",
          groupPrice: [
            {
              name: "",
              price: "11.95",
            },
          ],
          items: [
            {
              name: "Eggplant Parmigiana",
            },
            {
              name: "Chicken Parmigiana",
            },
            {
              name: "Veal Parmigiana",
            },
            {
              name: "Zucchini Parmigiana",
            },
            {
              name: "Pork Ribs Parmigiana",
            },
          ],
        },
        {
          sectionTitle: "Extra Sides",
          groupPrice: [
            {
              name: "",
              price: "3.95",
            },
          ],
          items: [
            {
              name: "Famous Meatball",
            },
            {
              name: "Italian Mild Sausage",
            },
            {
              name: "Sweet Peppers",
            },
            {
              name: "Hot Peppers",
            },
          ],
        },
        {
          sectionTitle: "Daily Specialties",
          sectionType: "twoColumnBullet",
          groupPrice: [
            {
              name: "",
              price: "Prices Vary",
            },
          ],
          items: [
            {
              name: "Sausage & Peppers (oil and garlic over pasta)",
            },
            {
              name: "Pork or Chicken Cacciatore (peppers, mushrooms, onions)",
            },
            {
              name: "Stuffed Peppers (meat, rice, mushrooms)",
            },
            {
              name: "Pasta & Peppers",
            },
            {
              name: "Pasta & Vegetables",
            },
            {
              name: "Cioppino (pasta, seafood)",
            },
            {
              name: "Linguini Puttanesca (oil, garlic, anchovies, capers, olives)",
            },
            {
              name: "Linguini Pesto (pasta, seafood)",
            },
          ],
        },
        {
          sectionTitle: "Pasta",
          sectionSubtitle:
            "(Served w/ Homemade Neopolitan Style Tomato Sauces)",
          sectionFooter: "(Additional $2.00 for Meat Sauce)",
          items: [
            {
              name: "Spaghetti",
              price: "9.95",
            },
            {
              name: "Capellini",
              price: "9.95",
            },
            {
              name: "Mostaccioli",
              price: "9.95",
            },
            {
              name: "Baked Ziti",
              price: "11.95",
            },
            {
              name: "Lasagna",
              price: "11.95",
            },
            {
              name: "Manicotti",
              price: "11.95",
            },
             {
              name: "Pasta Cardinale",
              price: "11.95",
            },
            {
              name: "Stuffed Shells",
              price: "11.95",
            },
            {
              name: "Ravioli - Cheese or Meat",
              price: "11.95",
            },
            {
              name: "Tortellini",
              price: "11.95",
            },
            {
              name: "Linguini Clams - Red or White",
              price: "11.95",
            },
            {
              name: "Linguini Alfredo",
              price: "11.95",
            },
            {
              name: "Linguini Pesto",
              price: "11.95",
            },
          ],
        },
        {
          sectionTitle: "Cold Sandwiches",
          sectionSubtitle:
            "(Served w/ lettuce, tomatoes, onions & our House Dressing)",
          groupPrice: [
            {
              name: "",
              price: "11.95",
            },
          ],
          items: [
            {
              name: "Ham & Provolone",
            },
            {
              name: "Salami & Provolone",
            },
            {
              name: "Capocollo & Provolone",
            },
            {
              name: "Giuseppe's Sub (cold cut combination)",
            },
          ],
        },
        {
          sectionTitle: "Hot Sandwiches",
          groupPrice: [
            {
              name: "",
              price: "11.95",
            },
          ],
          items: [
            {
              name: "Italian Beef",
            },
            {
              name: "Italian Sausage",
            },
            {
              name: "Famous Meatballs",
            },
            {
              name: "Eggplant",
            },
            {
              name: "Chicken Breast",
            },
            {
              name: "MB Sausage Combo",
            },
          ],
        },
        {
          sectionTitle: "Extras",
          groupPrice: [
            {
              name: "",
              price: "3.95",
            },
          ],
          items: [
            {
              name: "Hot Peppers",
            },
            {
              name: "Sweet Peppers",
            },
            {
              name: "Mozzarella",
            },
          ],
        },
        {
          sectionTitle: "Homemade Desserts",
          items: [
            {
              name: "Cheese Cake",
              price: "6.95",
            },
            {
              name: "Cannoli",
              price: "6.95",
            },
            {
              name: "Tiramisu",
              price: "6.95",
            },
            {
              name: "Spumoni",
              price: "6.95",
            },
            {
              name: "Tartufo",
              price: "6.95",
            },
            {
              name: "Espresso",
              price: "6.95",
            },
            {
              name: "Cappuccino",
              price: "6.95",
            },
          ],
        },
        {
          sectionTitle: "Drinks",
          sectionType: "bulletCluster",
          groupPrice: [
            {
              name: "",
              price: "4.00",
            },
          ],
          items: [
            {
              name: "Dr. Pepper",
            },
            {
              name: "7-Up",
            },
            {
              name: "RC Cola",
            },
            {
              name: "Diet RC Cola",
            },
            {
              name: "Iced Tea",
            },
            {
              name: "Lemonade",
            },
            {
              name: "Coffee",
            },
            {
              name: "Milk",
            },
          ],
        },
      ],
    },
    {
      menuTitle: "Dinner",
      hours: "5PM - 8:30PM",
      sections: [
        {
          sectionTitle: "Appetizers",
          items: [
            {
              name: "Gnocchi",
              price: "14.95",
            },
            {
              name: "Pepperonata",
              price: "14.95",
            },
            {
              name: "Eggplant",
              price: "14.95",
            },
            {
              name: "Garlic Bread",
              price: "6.95",
            },
            {
              name: "Pizza Bread",
              price: "6.95",
            },
          ],
        },
        {
          sectionTitle: "Soups",
          sectionSubtitle: "(Changed daily)",
          groupPrice: [
            {
              name: "Cup",
              price: "4.95",
            },
            {
              name: "Bowl",
              price: "7.95",
            },
          ],
          items: [
            {
              name: "Pasta e fagioli",
            },
            {
              name: "Lentil",
            },
            {
              name: "Vegetable",
            },
            {
              name: "Minestrone",
            },
            {
              name: "Split Pea",
            },
            {
              name: "Stracciatella",
            },
            {
              name: "Cabbage Potato",
            },
          ],
        },
        {
          sectionTitle: "Salads",
          items: [
            {
              name: "Dinner Salad",
              price: "3.95",
            },
            {
              name: "Giuseppe's Salad",
              price: "14.95",
            },
            {
              name: "Provolone",
              price: "14.95",
            },
            {
              name: "Antipasto",
              price: "14.95",
            },
            {
              name: "Tomato Salad",
              price: "14.95",
            },
            {
              name: "Mozzarella Caprese",
              price: "14.95",
            },
          ],
        },
        {
          sectionTitle: "Pasta",
          sectionSubtitle:
            "(Served w/ Homemade Neopolitan Style Tomato Sauces)",
          sectionFooter: "(Additional $2.00 for Meat Sauce)",
          items: [
            {
              name: "Spaghetti",
              price: "11.95",
            },
            {
              name: "Capellini",
              price: "11.95",
            },
            {
              name: "Mostaccioli",
              price: "11.95",
            },
            {
              name: "Baked Ziti",
              price: "18.95",
            },
            {
              name: "Pasta Cardinale",
              price: "18.95",
            },
            {
              name: "Lasagna",
              price: "18.95",
            },
            {
              name: "Manicotti",
              price: "18.95",
            },
            {
              name: "Stuffed Shells",
              price: "18.95",
            },
            {
              name: "Ravioli - Cheese or Meat",
              price: "18.95",
            },
            {
              name: "Tortellini",
              price: "21.95",
            },
            {
              name: "Linguini Clams - Red or White",
              price: "21.95",
            },
            {
              name: "Linguini Alfredo",
              price: "21.95",
            },
            {
              name: "Linguini Pesto",
              price: "21.95",
            },
            {
              name: "Linguini Puttanesca",
              price: "21.95",
            },
            {
              name: "Pasta e Mushrooms",
              price: "21.95",
            },
            {
              name: "Pasta e Peppers",
              price: "21.95",
            },
            {
              name: "Pasta e Vegetables",
              price: "21.95",
            },
          ],
        },
        {
          sectionTitle: "Daily Specialties",
          groupPrice: [
            {
              name: "",
              price: "Prices Vary",
            },
          ],
          items: [
            {
              name: "Alessandro Special (lemon chicken)",
            },
            {
              name: "Peppino Special (baked pasta w/meats)",
            },
            {
              name: "Franco Special (pasta, pork, spinach)",
            },
            {
              name: "Pasta Alex (spicy sausage, oil, garlic, tomato, cream, pesto)",
            },
            {
              name: "Sausage & Peppers (oil and garlic over pasta)",
            },
            {
              name: "Pork or Chicken Cacciatore (peppers, mushrooms, onions)",
            },
            {
              name: "Stuffed Peppers (meat, rice, mushrooms)",
            },
            {
              name: "Cioppino (pasta, seafood - Friday & Saturday Only)",
            },
            {
              name: "Veal & Peppers (oil, garlic, sauce)",
            },
            {
              name: "Eggplant Rollatini (spinach, ricotta)",
            },
          ],
        },
        {
          sectionTitle: "House Specialties",
          groupPrice: [
            {
              name: "",
              price: "21.95",
            },
          ],
          items: [
            {
              name: "Eggplant Parmigiana",
            },
            {
              name: "Chicken Parmigiana",
            },
            {
              name: "Veal Parmigiana",
            },
            {
              name: "Pork Ribs Parmigiana",
            },
          ],
        },
        {
          sectionTitle: "Extra Sides",
          groupPrice: [
            {
              name: "",
              price: "3.95",
            },
          ],
          items: [
            {
              name: "Famous Meatball",
            },
            {
              name: "Italian Mild Sausage",
            },
            {
              name: "Sweet Peppers",
            },
            {
              name: "Hot Peppers",
            },
          ],
        },
        {
          sectionTitle: "Homemade Desserts & Cafe",
          items: [
             {
              name: "Chocolate Cake (Flourless Chocolate Espresso)",
              price: "7.95",
            },
            {
              name: "Cheese Cake",
              price: "7.95",
            },
            {
              name: "Cannoli",
              price: "7.95",
            },
            {
              name: "Tiramisu",
              price: "7.95",
            },
            {
              name: "Spumoni",
              price: "7.95",
            },
            {
              name: "Tartufo",
              price: "7.95",
            },
            {
              name: "Espresso",
              price: "3.95",
            },
            {
              name: "Cappuccino",
              price: "7.95",
            },
          ],
        },
        {
          sectionTitle: "Drinks",
          sectionType: "bulletCluster",
          groupPrice: [
            {
              name: "",
              price: "4.00",
            },
          ],
          items: [
            {
              name: "Mr. Pibb",
            },
            {
              name: "7-Up",
            },
            {
              name: "RC Cola",
            },
            {
              name: "Diet RC Cola",
            },
             {
              name: "Lemonade",
            },
            {
              name: "Iced Tea",
            }, 
            {
              name: "Coffee",
            },
            {
              name: "Milk",
            },
           
          ],
        },
      ],
    },
  ],
};

export default sampleMenu;
