import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div class="footer-main">
      Copyright © 2017 Ristorante Giuseppe Scottsdale - All Rights Reserved.
    </div>
  );
};

export default Footer;
