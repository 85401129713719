import React from "react";
import "./aboutUs.css";

import liquidRx from "../../images/liquidTherapy.jpg";
import penne from "../../images/penne.jpg";
import interior from "../../images/interior.jpg";
import wallpaper from "../../images/wallpaper.png";
import PictureFrame from "../PictureFrame/PictureFrame";

const AboutUs = () => {
  return (
    <div
      className="about-us-main"
      style={{ backgroundImage: `url(${wallpaper})` }}
    >
      <h1 className="about-us-title">Family Owned and Operated Since 1989</h1>
      <hr></hr>
      <div className="about-us-image-container">
        <PictureFrame imageName="groupFun"></PictureFrame>
        <PictureFrame></PictureFrame>
        <PictureFrame imageName="celeb"></PictureFrame>
        {/* <div
          className="about-us-image"
          style={{ backgroundImage: `url(${liquidRx})` }}
        >
          <div>It's all about food, family and friends</div>
        </div>
        <div
          className="about-us-image"
          style={{ backgroundImage: `url(${penne})` }}
        >
          <div>Fresh Italian food with an attitude</div>
        </div>
        <div
          className="about-us-image"
          style={{ backgroundImage: `url(${interior})` }}
        >
          <div>
            Stop in and let our family prepare a delicious meal for you today
          </div>
        </div> */}
      </div>

      <p>
        Ristorante Giuseppe – the only “true” Italian restaurant in Scottsdale,
        AZ that makes you feel right at home. It’s all about food and family
        here, just like in Italy. Delicious, authentic, Italian food made to
        order that never disappoints.
      </p>
      <p>
        Giuseppe’s is family owned and operated since 1989. Our amazing culinary
        staff has been with us the whole time with 30+ years of combined
        experience. Come in tonight and you’ll be taken care of by our family,
        whether the sons are serving you or you’re enjoying Rica’s homemade
        desserts.
      </p>
      <p>
        BYOB – Giuseppe’s invites you to bring your own choice of wine to
        accompany your meal. We do not charge a corkage fee, as wine is an
        important complement to any Italian meal. Legal serving limits apply.
      </p>
      <hr></hr>
    </div>
  );
};

export default AboutUs;
