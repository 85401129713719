import React, { useState } from "react";
import MenuSection from "../MenuSection/MenuSection";
import "./menu.css";

const Menu = (props) => {
  const [menuIndex, setMenuIndex] = useState(0);

  return (
    <div className="menu-main" id="menu">
      <div className="menu-title-container">
        {props.menu.menus.map((menu, i) => {
          return (
            <h2
              className={"menu-title " + (menuIndex === i ? "active-menu" : "")}
              onClick={() => setMenuIndex(i)}
            >
              {menu.menuTitle}
            </h2>
          );
        })}

        {/* <h2 className="menu-title">{props.menu.menus[menuIndex].menuTitle}</h2> */}
      </div>
      <h3>{props.menu.menus[menuIndex].hours}</h3>
      <div className={"menu-nav " + (menuIndex === 1 ? "dinner-menu" : "")}>
        {props.menu.menus[menuIndex].sections.map((section, i) => {
          return (
            <a
              href={`#${section.sectionTitle
                .split(" ")
                .join("")
                .toLowerCase()}`}
            >
              {section.sectionTitle}
            </a>
          );
        })}
      </div>
      <div className="menu-body">
        {props.menu.menus[menuIndex].sections.map((section, i) => {
          return <MenuSection section={section}></MenuSection>;
        })}
      </div>
    </div>
  );
};

export default Menu;
