import React from "react";
import "./App.css";
import Header from "./components/Header/Header";
import HeroBanner from "./components/HeroBanner/HeroBanner";
import AboutUs from "./components/AboutUs/AboutUs";
import ContactUs from "./components/ContactUs/ContactUs";
import Map from "./components/Map/Map";
import Footer from "./components/Footer/Footer";
import Menu from "./components/Menu/Menu";
import sampleMenu from "./sampleData/sampleMenu";
import wallpaper from "./images/wallpaper.png";
import AlertBar from "./components/AlertBar/AlertBar";

function App() {
  return (
    <div className="App" style={{ backgroundImage: `url(${wallpaper})` }}>
      <AlertBar alertText="Don't forget, Ristorante Giuseppe is CASH ONLY and BYOB! "></AlertBar>
      <Header headerTitle="Ristorante Giuseppe Scottsdale"></Header>
      <HeroBanner></HeroBanner>
      <AboutUs></AboutUs>
      <Menu menu={sampleMenu}></Menu>
      <ContactUs></ContactUs>
      <Map></Map>
      <Footer></Footer>
    </div>
  );
}

export default App;
