import React from "react";
import "./heroBanner.css";
import penne from "../../images/penne2.jpg";
import blackAndWhite from "../../images/blackAndWhite.png";

const HeroBanner = () => {
  return (
    <div className="hero-banner-main">
      <div
        src={penne}
        className="hero-banner-image"
        style={{
          background: `radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 8%, rgba(0,0,0,0.65) 100%), url(${penne}) no-repeat center center scroll`,
          backgroundSize: "cover",
        }}
      >
        <div className="hero-banner-text">
          <img
            src={blackAndWhite}
            style={{ height: "200px" }}
            alt="Ristorante Giuseppe Scottsdale - Italian Restaurant - Logo"
          ></img>

          <h1>WELCOME TO RISTORANTE GIUSEPPE SCOTTSDALE</h1>
          <a href="#menu">View Menu</a>
          <a className="order-online-button" href="https://order.online/store/RistoranteGiuseppe-954060/en-US/?hideModal=true&pickup=true">Order Online</a>
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
