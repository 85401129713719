import React from "react";
import "./map.css";

const Map = () => {
  return (
    <div className="map-main">
      <iframe
        title="13610 N Scottsdale Rd. Scottsdale, AZ. 85254 - Google Map"
        width="100%"
        height="450"
        frameborder="0"
        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ8VWBs0J0K4cR_SxnkOp3L30&key=AIzaSyDpACLgWNhX_1qHf6VVdWtWiRSzFv52PNM"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default Map;
