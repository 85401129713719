import React from "react";
import "./menuItem.css";

const MenuItem = (props) => {
  return (
    <div className="menu-item-main">
      <p className="menu-item-name">{props.name}</p>
      <p className="menu-item-price">{props.price}</p>
    </div>
  );
};

export default MenuItem;
