import React from "react";
import MenuItem from "../MenuItem/MenuItem";
import "./menuSection.css";

const MenuSection = (props) => {
  return !props.section.groupPrice ? (
    <div id={props.section.sectionTitle.split(" ").join("").toLowerCase()}>
      <h2 className="menu-section-title">{props.section.sectionTitle}</h2>
      {props.section.sectionSubtitle ? (
        <h3 className="menu-section-subtitle">
          {props.section.sectionSubtitle}
        </h3>
      ) : null}
      <div>
        {props.section.items.map((item) => {
          return <MenuItem name={item.name} price={item.price}></MenuItem>;
        })}
      </div>
      {props.section.sectionFooter ? (
        <h3 className="menu-section-subtitle menu-section-footer">
          {props.section.sectionFooter}
        </h3>
      ) : null}
    </div>
  ) : (
    <div id={props.section.sectionTitle.split(" ").join("").toLowerCase()}>
      <h2 className="menu-section-title">{props.section.sectionTitle}</h2>
      {props.section.sectionSubtitle ? (
        <h3 className="menu-section-subtitle">
          {props.section.sectionSubtitle}
        </h3>
      ) : null}
      <div style={{ display: "grid", gridTemplateColumns: "1fr 150px" }}>
        <div>
          {props.section.items.map((item) => {
            return <MenuItem name={item.name} price={item.price}></MenuItem>;
          })}
        </div>
        <div>
          {props.section.groupPrice.map((groupItem) => {
            return (
              <MenuItem
                name={groupItem.name}
                price={groupItem.price}
              ></MenuItem>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MenuSection;
