import React from "react";
import "./pictureFrame.css";

import liquidRx from "../../images/liquidTherapy.jpg";
import groupFun from "../../images/groupFun.jpg";
import serving from "../../images/serving.jpg";
import celeb from "../../images/celeb.jpg";

const PictureFrame = (props) => {
  let targetImage;
  switch (props.imageName) {
    case "serving":
      targetImage = serving;
      break;
    case "celeb":
      targetImage = celeb;
      break;
    case "groupFun":
      targetImage = groupFun;
      break;
    case "liquidRx":
      targetImage = liquidRx;
      break;
    default:
      targetImage = serving;
      break;
  }

  return (
    <div class="border">
      <div class="frame">
        <div
          class="image"
          style={{ backgroundImage: `url(${targetImage})` }}
        ></div>
      </div>
    </div>
  );
};

export default PictureFrame;
