import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faYelp } from "@fortawesome/free-brands-svg-icons";
import blackAndWhite from "../../images/blackAndWhite.png";
import "./contactUs.css";

const ContactUs = () => {
  return (
    <div className="contact-us-main">
      <h1 className="contact-us-title">CONTACT US</h1>
      <hr></hr>
      <p>
        We love our customers, feel free to visit during normal business hours.
      </p>
      <div>
        <img
          src={blackAndWhite}
          style={{ height: "300px" }}
          alt="Ristorante Giuseppe Scottsdale - Italian Restaurant - Logo"
        ></img>
        <h2>Ristorante Giuseppe Scottsdale</h2>
        <p>13610 N Scottsdale Rd. Scottsdale , AZ. 85254</p>
        <a href="tel:4809914792">(480) 991-4792</a>
      </div>
      <div>
        <h2>Hours</h2>
        <p>Monday - Friday</p>
        <p>Lunch (11:30 - 2:30PM)</p>
        <p>Dinner (5:00 - 8:30PM)</p>
        <p>Saturday: 5:00 - 8:30PM</p>
        <p>Closed Sunday</p>
      </div>
      <div className="contact-icon-container">
        <a
          className="contact-icon"
          style={{ color: "#4267B2", fontSize: "1.5em" }}
          href="https://www.facebook.com/Giuseppescottsdale/"
          title="Visit Facebook"
          rel="noopener"
          target="_blank"
        >
          <FontAwesomeIcon icon={faFacebookSquare}></FontAwesomeIcon>
        </a>
        <a
          className="contact-icon"
          style={{ color: "#D32323", fontSize: "1.5em", marginLeft: ".3em" }}
          href="https://www.yelp.com/biz/ristorante-giuseppe-scottsdale"
          title="Visit Yelp"
          rel="noopener"
          target="_blank"
        >
          <FontAwesomeIcon icon={faYelp}></FontAwesomeIcon>
        </a>
      </div>
    </div>
  );
};

export default ContactUs;
