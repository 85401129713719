import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import wallpaper from "../../images/wallpaper.png";
import logo from "../../images/logo.png";
import "./header.css";

const Header = () => {
  return (
    <div
      className="header-main"
      style={{ backgroundImage: `url(${wallpaper})` }}
    >
      <img
        src={logo}
        style={{ height: "90px" }}
        alt="Ristorante Giuseppe Scottsdale - Italian Restaurant - Logo"
      ></img>
      <a
        className="header-icon hide-when-small"
        style={{ color: "#4267B2", fontSize: "1.5em" }}
        href="https://www.facebook.com/Giuseppescottsdale/"
        rel="noopener"
        target="_blank"
      >
        <FontAwesomeIcon icon={faFacebookSquare}></FontAwesomeIcon>
      </a>
    </div>
  );
};

export default Header;
